<template>
  <div class="header">
    <img src="../assets/mao.jpg" alt="" class="header-img" />
    <div class="logo-content">冇先生的信箱</div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  margin-top: 100px;
}
.header-img {
  width: 20%;
  height: 20%;
  border-radius: 50%;
}
.logo-content {
  color: #4dbeb5;
  padding-top: 10px;
}
@media screen and (min-width: 750) {
  .logo-content {
    color: #4dbeb5;
    font-size: 30px;
    padding-top: 10px;
  }
}
</style>
