import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import axios from "axios";

axios.defaults.baseURL = "/api/";
// axios.defaults.baseURL = 'http://localhost:8088/api/';
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$http = axios;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
