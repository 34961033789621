<template>
  <div>
    <div class="home" v-if="true">
      <router-link tag="button" class="btn" to="/write">写留言</router-link>
      <router-link tag="button" class="btn" to="/look">看留言</router-link>
    </div>
    <div v-else>
      <h1>请使用手机端访问树洞!</h1>
    </div>

    <a href="https://beian.miit.gov.cn/" class="beian"> 粤ICP备2023002307号 </a>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      mobile: false,
      links: "",
    };
  },
  created() {
    var sUserAgent = navigator.userAgent;
    if (
      sUserAgent.indexOf("Android") > -1 ||
      sUserAgent.indexOf("iPhone") > -1 ||
      sUserAgent.indexOf("iPad") > -1 ||
      sUserAgent.indexOf("iPod") > -1 ||
      sUserAgent.indexOf("Symbian") > -1
    ) {
      this.mobile = true;
      this.getNoteLinks();
    } else {
      alert("树洞暂时不支持电脑留言,请使用手机访问!");
      this.mobile = false;
    }
  },

  methods: {
    //前往匿名短信
    toNote() {
      window.location.href = this.links;
    },

    //获取匿名短信链接
    getNoteLinks() {
      this.$http
        .get("/links/getLinks", {
          params: {
            title: "匿名短信",
          },
        })
        .then((res) => {
          this.links = res.data.data.links || "";
        });
    },
  },
};
</script>
<style scoped>
.home {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.home button {
  border: 0;
  background: #4dbeb5;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .home button {
    width: 65%;
    height: 45px;
    line-height: 45px;
    font-size: 15px;
    font-weight: 700;
    margin: 0 auto;
    border-radius: 5px;
    text-align: center;
  }
  .home button.btn {
    /* margin-top: 20%; */
    margin-top: 13%;
  }
}

a.beian {
  position: fixed;
  color: #4dbeb5;
  bottom: 10px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}
</style>
