import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "冇先生",
    },
  },
  {
    path: "/look",
    name: "Look",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Look.vue"),
    meta: {
      title: "冇先生",
    },
  },
  {
    path: "/write",
    name: "Write",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Write.vue"),
    meta: {
      title: "冇先生",
    },
  },
  {
    path: "/error",
    name: "Error",
    component: () => import(/* webpackChunkName: "about" */ "../views/404.vue"),
    meta: {
      title: "冇先生",
    },
  },
];

const router = new VueRouter({
  routes,
});

export default router;
