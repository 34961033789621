<template>
  <div id="app">
    <div class="boxer">
      <Header />
      <router-view />
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
export default {
  components: {
    Header,
  },
};
</script>
<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body,
#app {
  height: 100%;
  width: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}
.boxer {
  position: relative;
  width: 900px;
  height: 100%;
  min-height: calc(100vh);
  margin: 0 auto;
  background: #eee;
  overflow-x: hidden;
  overflow-y: auto;
}
@media screen and (max-width: 768px) {
  .boxer {
    width: 100%;
    height: 100%;
    background: #f8f8f8;
  }
}
</style>
